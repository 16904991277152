
export default {
    props: ['item','active', 'index'],
    data(){
        return{
            product: null
        }
    },
    computed: {
        isDark(){
            return this.$store.state.layout.displayMode == 'dark';
        },
        isWebpSupported(){
            return this.$store.state.layout.isWebpSupported;
        },
        isMobile(){
            return this.$store.state.layout.isMobile;
        },
        computedPrice(){
                if (![undefined, null].includes(this.price)) return this.price
                if(this.product){
                    if(![undefined, null].includes(this.product.price)) return this.product.price
                    return  product.prices[0].Offers[0].OffersPerSalesChannel[0].Price
                }
                return 0
            },
        computedListPrice(){
                if(this.product){
                    if(![undefined, null].includes(this.product.listPrice)) return this.product.listPrice
                    return  product.prices[0].Offers[0].OffersPerSalesChannel[0].ListPricerice
                }
                return 0
            },
        mw(){
            return parseInt(this.$store.state.scroll.mw);
        },
    },
    created(){
        this.getProduct();
    },
    mounted(){
    },
    methods: {
        async getProduct(){
            var product = await this.$axios.post('/products/search/new', 
            {
                filters : {productId: this.item.vtexID},
                pagination: {
                        page_size: 1,
                        page: 1
                    },
            }).then(({data}) => {
                return data.products && data.products.length ? data.products[0] : null;
            }).catch(err => {
                return null;
            })
            this.product = product;
            this.$emit('slideLoaded');
        }
    }

}
